@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "../../common/style/global.scss";

.dashboard-tab-wrapper {
  background-color: $body-bg-color;
  height: auto;
  padding: 25px 42px 40px 33px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .dashboard {
    color: $active-header-text;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 25px;
  }
  .filter-insights-container {
    display: flex;
    padding: 14px;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid $border-grey;
    background: #fafbfa;
    .filter-select {
      display: flex;
      gap: 20px;
      margin-right: 350px;
      .select-tab {
        width: 300px;
        display: flex;
        // padding: 4px 12px;
        align-items: flex-start;
      }
    }
    .filter-title {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .dashboard-tab-child-one {
    display: grid;
    grid-template-areas: "content interest interest interest";
    gap: 20px;

    .lead-content-info-wrapper {
      grid-area: content;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .total-lead-container {
        display: grid;
        grid-template-columns: auto;
        padding: 22px 20px;
        align-items: flex-start;
        gap: 6px;
        border-radius: 6px;
        background: $white;

        .lead-number {
          color: $active-header-text;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
          margin-right: 2px;
        }

        .lead-number-text {
          color: $active-header-text;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
      }

      .highestSourceContainer {
        display: flex;
        padding: 22px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 21px;
        align-self: stretch;
        border-radius: 6px;
        background: $white;

        .highest-score-text {
          color: $active-header-text;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }

        .highest-scroe-value {
          color: $active-header-text;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.44px;
        }
      }
    }

    .lead-content-interest-wrapper {
      grid-area: interest;
      display: flex;
      flex-direction: row;
      gap: 22px;

      .content-type-interest-wrapper {
        width: 50%;
        display: flex;
        padding: 22px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 21px;
        border-radius: 6px;
        background: $white;

        .interest-text {
          color: $active-header-text;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }

        .interest-container {
          display: flex;
          flex-direction: column;
          gap: 5px;
          height: 100%;
        }

        .interest-container2 {
          justify-content: center;
        }

        .interest-container > div {
          display: flex;
          flex-direction: row;
          padding-top: 4px;
          gap: 12px;

          .interest-btn {
            display: flex;
            padding: 4px 10px;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            border-radius: 20px;
            width: 37px;

            p {
              color: $white;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 22px;
            }
          }

          .interest-label {
            color: $active-header-text;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            padding-top: 3px;
          }

          .interest-btn-one {
            background: $border-primary;
          }

          .interest-btn-two {
            background: #34b4de;
          }
          .interest-btn-three {
            background: #73d4f4;
          }

          .content-btn-one {
            background: #ffb61e;
          }
          .content-btn-two {
            background: #ffcd66;
          }
          .content-btn-three {
            background: #ffda8d;
          }
        }
      }
    }
  }

  .dashboard-tab-child-two {
    display: flex;
    flex-direction: row;
    gap: 22px;
    height: 350px;

    .jobFunContainer {
      background-color: $white;
      width: 58.5%;
      border-radius: 6px;
      padding: 22px 40px;
      margin-right: -39px;
    }

    .businessNatureContainr {
      width: 31.5%;
      display: flex;
      height: auto;
      padding: 22px 13px;
      flex-direction: column;
      align-items: flex-start;
      gap: 21px;
      flex-shrink: 0;
      align-self: stretch;
      border-radius: 6px;
      background-color: $white;
      margin-left: 40px;
      // height: 100%;
    }
  }
}

.businessDistributionContainer {
  width: 65%;
  display: flex;
  padding: 22px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 21px;
  align-self: stretch;
  border-radius: 6px;
  background: $white;
}

@media (min-width: 1920px) and (min-height: 1070px) {
  .jobFunContainer {
    width: 60% !important;
  }
}

@media (min-width: 1920px) and (min-height: 1070px) {
  .businessNatureContainr {
    width: 33% !important;
  }
}
