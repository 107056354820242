@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import "../../../common/style/global.scss";

.topic-container {
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;

  .topic-item {
    background-color: $body-bg-color;
    border-radius: 6px;
    padding: 3px 6px;
    color: $active-header-text;
    font-family: "Open Sans", sans-serif;
    display: inline-block;
    white-space: nowrap;
  }
}

.buyer-intent-score-container {
  padding: 15px 70px;
  display: grid;
  grid-template-columns: 10% 80%;
  gap: 10px;
  align-items: center;

  .buyer-intent-item-table {
    color: $active-header-text;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 350px;
    text-transform: capitalize;
  }

  .buyer-intent-score {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    .buyer-intent-rate {
      color: $white;
      font-weight: 600;
    }
    .buyer-intent-item {
      font-weight: 600;
      color: $active-header-text;
      font-size: 14px;
    }
  }
}

.expand-row-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 22px;
  border: 1px solid $border-primary;
  border-radius: 40px;
  gap: 8px;

  .expand-text {
    color: $border-primary;
    font-weight: 600;
  }
  .down-arrow {
    color: $border-primary;
    font-size: 16px;
  }
}

.lead-table-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  margin-top: -9px;

  .export-btn {
    padding: 9.5px 14.5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border: 1px solid $border-secoondary;
    border-radius: 4px;

    :hover {
      border: none;
    }
    p {
      color: $active-header-text;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 40;
  background-color: white;
  padding-bottom: 10px;
}

.company-list-lead-names {
  color: $white;
  width: 21px;
  height: 21px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;

  p {
    color: $white;
  }
}

.topic-error {
  gap: 10px;
  align-items: center;
  display: flex;
  padding: 6px;
  background-color: #eeeeef;
  > P {
    color: #022343;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
}

.error-img {
  padding-bottom: 2px;
}

.bar-containers {
  > canvas {
    height: 345px !important;
  }
}

.export-modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .export-modal-text-wrapper {
    margin-top: 20px;

    p {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.44px;

      span {
        font-weight: 700;
      }
    }
  }

  .export-btns-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;

    .refine-btn {
      display: flex;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 20px;
      flex-direction: column;
      align-items: center;
      border-radius: 6px;
      border: 1px solid #000;
      background: rgba(219, 227, 234, 0.24);
      color: #024;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
    }

    .export-now-btn {
      color: #fff;
      background: #024;
    }
  }
}

.column-sort-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  .sorter {
    display: flex;
    flex-direction: column;
  }
}

.emptyTopic {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  > div {
    color: #5b5b65;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 54%;
  }
}

.error-info {
  gap: 10px;
  align-items: center;
  display: flex;
  padding: 6px;
  background-color: #eeeeef;
  margin: 15px;
  .error-img {
    width: 18px;
    height: 18px;
  }
  > p {
    color: #022343;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
}

.topic-filter {
  margin: 8px;
  .topic-filter-inner-ontainer {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px;
    background-color: #eeeeef;
    > span {
      color: #000;
      font-size: 16px;
      font-weight: 500;
    }
    .button-label {
      color: #024;
      font-size: 12px;
      font-weight: 500;
    }
    .filter-button1 {
      background-color: #11a7d9;
      width: 50px;
      height: 25px;
      border: none;
      border-radius: 4px;
    }
    .filter-button2 {
      background-color: #b6d0e2;
      width: 50px;
      height: 25px;
      border: none;
      border-radius: 4px;
    }
  }
}
.content-main-container {
  display: flex;
}

.content-info {
  display: flex;
  width: 30%;
  height: 200px;
  padding: 25px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 7px;
  background: rgba(255, 210, 117, 0.2);
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  .info-container {
    width: 182px;
    height: 42px;
    > p {
      color: #000;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      // font-weight: 700;
      line-height: normal;
    }
  }
  .list-container {
    > div {
      > span {
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left: 5px;
      }
    }
  }
}

.loader {
  padding: 50px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.inner-list-container {
  display: flex;
  gap: 5px;
  .inner-text {
    color: #000;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
  }
}

.loader {
  margin-top: 100px;
  margin-bottom: 100px;
  align-items: center;
  padding: 50px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
