@import "../../common/style/global.scss";

.user-profile-container {
  margin-top: 16px;
}

.profile-row {
  display: flex;
  gap: 38px;
}

.profile-row-first-char {
  width: 55px;
  height: 45px;
  border-radius: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  p {
    font-size: 20px;
    font-weight: 500;
  }
}

.profile-detail {
  display: flex;
  flex-direction: column;
}

.profile-detail-row {
  display: flex;
  justify-content: space-between;
}

.profile-label {
  flex: 1;
}

.profile-value {
  flex: 2;
}

.profile-button-container {
  display: flex;
  justify-content: flex-end;
}

.cancel-button {
  color: #002244;
  font-size: 16px;
  font-weight: 500;
  padding: 2px 20px;
  align-items: center;
  margin: 4px;
  border: 1px solid #000;
}
