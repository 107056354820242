@import "../../style/global.scss";
.filter-check-modal-wrapper {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;

  h2 {
    text-align: center;
    color: #022343;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.filter-check-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;

  .continue-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 40px;
    padding-bottom: 30px;
    border-radius: 6px;
    border: 1px solid $active-header-text;
    background: $body-bg-color;
    color: #002244;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
  .apply-button {
    background-color: #002244 !important;
    color: $white;
    font-weight: 400;
  }
}

.terms-condition-wrapper {
  max-height: 300px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.terms-condition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .terms-accept {
    color: $white;
    background-color: #022343;

    p {
      text-align: center;
    }
  }
}

.rate-lead-title {
  display: flex;
  padding: 0px 6px 6px 6px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid $border-grey-primary;

  p {
    color: $active-header-text;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    span {
      font-weight: 700;
    }
  }

  .close-rate {
    border: none;
    background: none;
    border-bottom: none;
    box-shadow: none;

    .close-btn-icon {
      width: 24px;
      height: 24px;
      color: $active-header-text;
    }
  }
}

.terms-condition-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  .close-terms {
    cursor: pointer;
  }
}
