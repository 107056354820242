@import "../../common/style/global.scss";

.navbar-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-left: 306px;
  .navbar-logo {
    position: absolute;
    left: 32px;
    top: 16px;

    h1 {
      font-weight: 700;

      span {
        color: #ffd275;
      }
    }
  }

  .tab-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    // line-height: 24px;
  }

  .profile-dropdown {
    position: absolute;
    top: 30px;
    right: 80px;
    cursor: pointer;
  }
}

.logo-title {
  color: #df5a28;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.Profile-row-name-circle {
  width: 55px;
  height: 45px;
  border-radius: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;

  p {
    font-size: 20px;
    font-weight: 500;
  }
}

.profile-detail {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  p {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    line-height: 24px;
  }
}

.invite-team-member-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .inivte-team-member-top-container {
    display: flex;
    flex-direction: column;
    gap: 5px;

    p {
      color: $active-header-text;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }

    .invite-team-member-input-container {
      display: flex;
      flex-direction: row;
      gap: 15px;

      .input-box {
        border-radius: 2px;
        border: 1px solid #d9d9d9;
        background: var(--Neutral-1, #fff);
      }

      .send-btn {
        display: flex;
        padding-top: 8px;
        padding-bottom: 30px;
        padding-left: 40px;
        padding-right: 40px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        background: #024;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
      }
    }
  }
  .inivte-team-member-bottom-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .lead-row-name-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;

      .lead-row-name-circle {
        width: 28px;
        height: 28px;
        border-radius: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;

        p {
          font-size: 20px;
          font-weight: 500;
        }
      }

      .lead-row-name-text-wrapper {
        margin-top: 8px;

        .lead-row-name-text {
          font-size: 16px;
          font-weight: 700;
        }

        .lead-row-job-title {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .remove-access-container {
      display: flex;
      flex-direction: row;
      gap: 6px;
      align-items: center;
      cursor: pointer;

      p {
        color: #d0021b;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
