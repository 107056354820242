@import "../../common/style/global.scss";
.jobMainContainer {
  display: flex;
  // padding: 22px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 21px;
  align-self: stretch;
  // background: $white;
  height: auto;
  width: 100%;
  .jobTitle {
    color: $active-header-text;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
}

.nature-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  // align-items: center;
  .nature-title {
    color: $active-header-text;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    // align-items: center;
    margin-bottom: 20px;
    padding-left: 10px;
  }
}

.chart-container {
  width: 100%;
  max-width: 800px;
  position: relative;
  height: 400px;
}

// .chart-container {
//   > canvas {
//     width: 400px !important;
//     height: 260px !important;
//     align-items: center;
//   }
// }

.chart-container .chartjs-render-monitor {
  width: 100% !important;
}

.chart-container .chartjs-legend {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // width: 50px !important;
  word-break: break-word !important;
}

.bar-container {
  > canvas {
    height: 270px !important;
  }
}

@media (min-width: 1920px) and (min-height: 1070px) {
  .scrollBar {
    width: 1000px !important;
    max-width: 1000px !important;
  }
}
