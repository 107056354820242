@import "../../../common/style/global.scss";
.create-account {
  text-align: center;
  font-weight: 400;
  margin-top: 20px;
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #dadada;
}

.login-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 80px;
  background-color: white;
  padding: 30px;
  padding-top: 10px;
  width: 400px;
  height: auto;
  padding-bottom: 30px;
  border-radius: 12px;
  box-shadow: 5px 5px 5px 5px $shadow-default;

  .title {
    text-align: center;
    color: $bg-primary;
    font-size: 30px;
    font-weight: 700;
    margin-top: 10px;
  }
}

.label-container {
  margin-top: 6px;
  .lable {
    margin-top: 20px;
    float: left;
    margin-left: 2px;
    margin-bottom: 10px;
    color: #000;
  }
}

.button {
  background: #002d62;
  margin-top: 30px;
  width: 100%;
}

.button-disabled {
  width: 100%;
  background-color: $bg-primary;
  color: $white;
  height: 35% !important;
}

.alertBox > div {
  width: 100% !important;
  height: 40px !important;
}

.errorMsg {
  color: $form-error-color;
  margin-top: 5px;
  margin-bottom: 0px;
}

.alertBox > div {
  width: 100% !important;
  height: 40px !important;
}

.errorMsg {
  color: $form-error-color;
  margin-top: 5px;
  margin-bottom: 0px;
}
