@import "../IndividualLeads/IndividualLeads.style.scss";
@import "../../../common/style/global.scss";
.lead-name-container {
  display: flex;
  flex-direction: row;
  gap: "7px";
  padding: "6px";
  cursor: pointer;
  min-width: 118px;
}

.lead-names {
  color: $border-primary;
  font-size: 16px;
  font-weight: 500;
  margin-top: -3px;
  margin-left: 5px;
}

.lead-name-wrapper {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.checbox-company {
  display: flex;
  justify-content: center;
}
.loader {
  margin-top: 100px;
  margin-bottom: 100px;
  align-items: center;
  padding: 50px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
