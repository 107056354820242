/* Font Families */

/* Font Sizes */

* {
  margin: 0px;
  padding: 0px;
}

$fs-xs: 10px;

$fs-sm: 12px;

$fs-md: 14px;

$fs-lg: 16px;

$fs-xl: 20px;

/* Heading Font Sizes */

$h-1: 34px;

$h-2: 32px;

$h-3: 30px;

$h-4: 24px;

/* Font Weights */

$fw-light: 300;

$fw-normal: 400;

$fw-medium: 500;

$fw-semibold: 600;

$fw-bold: 700;

$fw-extrabold: 800;

$fw-black: 900;
