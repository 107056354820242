/* Global text  colors */

$white: #ffffff;

$body-bg-color: #dadada80;

$active-header-text: var(--Primary, #000);

$text-blue-color: #022343;

$border-primary: #11a7d9;

$border-secoondary: #00224480;

$border-grey: rgba(167, 188, 197, 0.2);

$border-grey-primary: #dadada80;

$blue-bg-primary: #11a7d93d;

$divider-bg: #0000000f;

$bg-primary: #002d62;

$shadow-default: #e1e1f7;

$light-sky-blue: #abeaff66;

/* Button Primary Colors */

$button-primary-color: #002244;

/* Forms */

$form-error-color: #ff0000;
