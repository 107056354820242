@import "../../../common/style/global.scss";

.registraton-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120vh;
  background-color: #dadada;
}
.registration-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 400px;
  border-radius: 12px;
  box-shadow: 5px 5px 5px 5px $shadow-default;
  padding: 30px;
  padding-top: 0px;

  .ant-form-item {
    margin-bottom: 18px;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.text {
  color: $bg-primary;
  font-size: 30px;
  font-weight: 700;
}
.submit {
  width: 100%;
  background-color: $bg-primary;
  color: $white;
}
.errorMsg {
  color: $form-error-color;
  margin-top: 5px;
  margin-bottom: 0px;
}

.terms-btn {
  border: none;
  padding-left: 4px;

  p {
    color: #4264fb;
  }
}

.export-btns-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;

  .refine-btn {
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #000;
    background: rgba(219, 227, 234, 0.24);
    color: #024;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    width: 49%;
  }

  .export-now-btn {
    color: #fff;
    background: #024;
  }
  .disable-btn {
    background-color: rgb(156, 177, 199);
  }
}

.change-password-wrapper {
  height: 100vh;

  .change-password-container {
    padding: 35px;
    margin-top: 0px;

    .change-password-header {
      margin-bottom: 20px;
    }

    .submit-btn {
      border: 1px solid #000;
    }
  }
}

.terms-cont {
  padding-top: 10px;
}
