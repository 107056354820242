@import "../../../../common/style/global.scss";
.filter-container {
  width: 100%;
  background-color: #fafbfa;
  display: flex;
  gap: 30 !important;
  flex-direction: column;
  height: 100%;
}
.filter-container > div > div > div > span > span > span > button {
  background-color: black !important;
}

.filter-title {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.customInput {
  width: 100%;
  .ant-input-affix-wrapper {
    border-radius: 0 !important;
  }
  .ant-input-group-addon {
    .ant-btn {
      border-radius: 0 !important;
      color: white !important;
    }
  }
}

.customCollaps .ant-collapse-item {
  border: none;
}

.customCollaps .ant-collapse-header {
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.3s ease;
}

.customCollaps .ant-collapse-content {
  border-top: none;
  border: none;
}

.customCollaps .ant-collapse-item-active .ant-collapse-header {
  border-bottom-color: #dadada;
}

.button-container {
  padding: 14px;
  display: flex;
  // gap: 10;
  align-items: center;
  justify-content: space-between;
  .clear-button {
    color: #df5a82;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    cursor: pointer;
  }
  .filter-button {
    background-color: #024;
    border-radius: 6px;
    width: 72%;
    height: 46px;
    color: #fff;
    > div {
      padding: 8px;
      > span {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        color: #fff;
      }
    }
  }
  .filter-button:hover {
    background-color: #024 !important;
    border-radius: 6px;
    width: 72%;
    height: 46px;
    color: #fff !important;
  }
}

.collaps-container {
  width: 100%;
}

.custom-collaps {
  background-color: #fff;
  width: 90%;
  margin-left: 10px;
  .ant-collapse-header {
    padding: 9px 16px !important;
  }
}

.parent-collaps {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 6;
  margin-top: -10px;
  max-height: 230px;
  overflow: auto;
  .child-collap {
    display: inline;
    padding: 6px 8px;
    color: #000;
    border-radius: 26px;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }
}

.count {
  margin-top: -18px;
  position: relative;
  float: right;
  // right: 25px;
  // top: -21px;
  color: #11a7d9;
  font-size: 10px;
  font-weight: 700;
}
