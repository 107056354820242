@import "../../../common/style/global.scss";
.landing-container {
  display: flex;
  gap: 15px;
  padding: 10px 15px;
  background-color: #dadada80;
}

.landing-first-child {
  height: inherit;
  width: 350px;
  background-color: #fafbfa;
  padding-top: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 6px;
  border: 1px solid rgba(167, 188, 197, 0.2);
}

.lead-title {
  font-weight: 700;
  font-size: 36px;
}

.tab-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
