@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import "../../../../common/style/global.scss";
.interactionContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .interactionList {
    display: flex;
    gap: 15px;
    flex-direction: row;
    align-items: center;
    .label {
      display: flex;
      flex-direction: column;
      width: 30px;
      height: 30px;
      padding: 3px;
      justify-content: center;
      align-items: center;
      border-radius: 51.462px;
      border: 2.331px solid #73d4f4;
      background: rgba(171, 234, 255, 0.8);
      .labeltext {
        color: $border-primary;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        // word-break: break-all;
        &.icon {
          font-size: 15px;
        }
      }
    }
    .interactionData {
      display: flex;
      flex-direction: column;
      .data {
        color: $active-header-text;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        // text-transform: capitalize;
        &.date {
          color: #024;
          font-weight: 400;
        }
      }
    }
  }
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black !important;
}

.intaction-container {
  margin-top: 16px;
  margin-bottom: 6px;
  height: 150px;
  overflow: auto;
}

.interaction-error {
  gap: 10px;
  align-items: center;
  display: flex;
  background-color: #eeeeef;
  width: 100%;
  padding: 6px;
  > p {
    color: #022343;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
  .error-img {
    padding-bottom: 2px;
  }
}

.interaction-main {
  width: 100%;
  .titile-container {
    display: flex;
    width: 100%;
    height: 28px;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #eeeeef;
    .interaction-title {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28.8px;
    }
  }
  > hr {
    color: red;
  }
}
