@import "../../../../common/style/global.scss";

.leads-from-same-company-container {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;

  :hover {
    background-color: rgba(17, 167, 217, 0.24);
  }

  .lead-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .lead-row-name-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;

      .lead-row-name-circle {
        width: 28px;
        height: 28px;
        border-radius: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;

        p {
          font-size: 20px;
          font-weight: 500;
        }

        p:hover {
          background-color: transparent;
        }
      }

      .lead-row-name-text-wrapper {
        margin-top: 8px;

        .lead-row-name-text {
          font-size: 16px;
          font-weight: 700;
        }

        .lead-row-name-text:hover {
          background-color: transparent;
        }

        .lead-row-job-title {
          font-size: 12px;
          font-weight: 500;
          color: #000;
        }

        .lead-row-job-title:hover {
          background-color: transparent;
        }
      }

      .lead-row-name-text-wrapper:hover {
        background-color: transparent;
      }
    }

    .lead-row-name-container:hover {
      background-color: transparent;
    }
  }
}
