@import "../../../../common/style/global.scss";

.indivisual-detail-container {
  .detail-wrapper {
    display: flex;
    width: 90%;
    justify-content: space-between;
    background-color: $white;
    margin: auto;
    padding-top: 15px;
    padding-bottom: 15px;

    .back-to-leads-button {
      font-weight: 700;
      font-size: 20px;
      border: none;
      background-color: $white;
      box-shadow: none;
      color: $active-header-text;
      border: none;
      cursor: pointer;

      .back-to-lead-arrow {
        margin-right: 13px;
      }
    }

    .profile-dropdown {
      position: absolute;
      top: 20px;
      right: 80px;
      cursor: pointer;
    }
  }
  .detail-body-container {
    background-color: $body-bg-color;
    display: flex;
    flex-direction: row;
    padding: 15px;
    gap: 6px;

    .chlid-container {
      display: flex;
      flex-direction: column;
      gap: 6px;
      width: 45%;
      height: 100vh;
      -ms-overflow-style: none;
      scrollbar-width: none;

      .lead-contact-container {
        background-color: $white;
        padding: 10px 15px;
        border-radius: 8px;
        border: 1px solid $border-grey;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .icp-container {
          display: flex;
          gap: 5px;
          flex-direction: column;

          .lead-personal-detail-container {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .lead-name {
              font-size: 24px;
              font-weight: 600;
              line-height: 29px;
            }

            .lead-job-title {
              font-size: 14px;
              font-weight: 500;
              line-height: normal;
            }
          }
        }

        .lead-contact-info-cont {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .lead-contact-logo {
            display: flex;
            flex-direction: row;
            gap: 12px;
            justify-content: flex-start;
            align-items: center;

            .lead-contact-info-txt {
              color: $active-header-text;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 21px;
              overflow: hidden;
              text-overflow: ellipsis;
              // white-space:;
            }

            .lead-contact-info-phone {
              color: $text-blue-color;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }

        .lead-info-btns-cont {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .rate-lead-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 18px;
            border-radius: 6px;
            background-color: $active-header-text;
            width: 100%;
            gap: 6px;
            border: none;

            .rate-btn-text {
              color: #ffd275;
              font-size: 16px;
              font-weight: 500;
              line-height: 21px;
              border: none;
            }
          }

          .export-crm-btn {
            background-color: #ffd275;

            .export-crm-text {
              color: $active-header-text;
            }
          }
        }
      }

      .lead-source-wrapper {
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-radius: 8px;
        background-color: $white;

        .lead-source-lead {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        .fi-europe {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          color: $active-header-text;
        }
      }

      .overViewTabBox {
        height: auto;
        background-color: $white;
        padding: 0px 15px;
        border-radius: 8px;
        // overflow: auto;
        .ant-tabs > .ant-tabs-nav {
          padding: 0px 20px;
        }
      }
    }
  }
}

.rate-lead-dialog-wrapper {
  display: flex;
  padding: 0px 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;

  .rate-lead-title {
    display: flex;
    padding: 0px 6px 6px 6px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    border-bottom: 1px solid $border-grey-primary;

    p {
      color: $active-header-text;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      span {
        font-weight: 700;
      }
    }

    .close-rate {
      border: none;
      background: none;
      box-shadow: none;
    }
  }

  .rate-lead-activity-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    width: 100%;

    .rating-history-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .rating-title {
        color: #000;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .rating-history-details-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        align-self: stretch;
        max-height: 150px;
        overflow: scroll;
        overflow-x: hidden;

        .rating-history-details {
          display: flex;
          padding: 4px 0px 4px 13px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-radius: 4px;
          border: 1px solid rgba(218, 218, 218, 0.5);
          background: rgba(219, 227, 234, 0.24);
          width: 100%;

          .rate-date {
            color: #000;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            .date {
              font-weight: 700;
            }
          }

          .rated-text-details {
            color: #666;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            .rating-text {
              font-weight: 700;
            }
          }

          .rate-comment {
            color: #666;
            font-size: 14px;
            font-style: italic;
            font-weight: 700;
            line-height: normal;
          }

          .rate-lead-score-wrapper {
            display: flex;
            width: 143px;
            padding: 13px 10px;
            justify-content: center;
            align-items: center;
            gap: 5px;
            align-self: stretch;
            border-radius: 4px;
            background: #fff;

            p {
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }
        }
      }
    }

    .rate-title-in-relavent-wrapper {
      display: flex;
      padding: 0px 5px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      .rate-title-in-relavent {
        color: $text-blue-color;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .rate-btn-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        flex-wrap: wrap;

        .rate-btn-label-container {
          display: flex;
          width: 109px;
          padding: 40px 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          height: 65px;
          border-radius: 8px;
          border: 2px solid rgba(218, 218, 218, 0.5);
          background: #fff;

          .rate-number {
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .rate-text {
            white-space: initial;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .selected-rate-btn {
          border-radius: 8px;
          border: 3px solid #024;
          background: rgba(2, 35, 67, 0.1);
        }
      }
    }

    .feedback-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      width: 100%;

      .feedback {
        color: $text-blue-color;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .comment {
        color: $text-blue-color;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration-line: underline;
      }
    }
  }

  .rate-btn-submit-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    .cancel-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px 40px;
      padding-bottom: 30px;
      border-radius: 6px;
      border: 1px solid $active-header-text;
      background: $body-bg-color;
      color: #002244;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
    }

    .skip-btn {
      border: none;
      color: grey;
      font-weight: 600;
      cursor: pointer;
      font-size: 16px;
      margin-top: 8px;
    }

    .save-rating-btn {
      background-color: #002244;
      color: $white;
    }
  }
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black !important;
}

.company-overview-wrapper {
  background-color: $white;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid $border-grey;
  height: 25%;

  .company-overview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;

    .company-text {
      margin: 0px;
      color: $active-header-text;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28.8px;
    }

    .company-overview-items-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      .company-overview-item-container {
        display: flex;
        align-items: center;
        gap: 6px;
        justify-content: center;

        .company-overview-left-item {
          display: flex;
          width: 120px;
          flex-direction: row;
          align-items: flex-start;
          gap: 3px;

          p {
            color: #677b8e;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            margin: 0px;
          }
        }
        .company-overview-right-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-wrap: wrap;

          p {
            color: $active-header-text;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 21px;
            text-decoration-line: underline;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 120px;
            white-space: nowrap;
          }

          .no-underline {
            text-decoration-line: none;
            color: $text-blue-color;
          }
        }
      }
    }
  }
}

.notes-wrapper {
  display: flex;
  padding: 10px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
  border-radius: 8px;
  background: $white;
  border: 1px solid $border-grey;
  max-height: 340px;
  height: 53%;

  .notes-title {
    color: #263238;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.44px;
  }

  .notes-history-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
  }
  .notes-section {
    display: flex;
    padding: 0px 1px;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;

    .lead-literal {
      display: flex;
      width: 26px;
      height: 26px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 16px;
      background-color: #a6bcc6;

      p {
        margin: 0px;
        color: $white;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }
    }

    .lead-user-literal {
      background-color: #002d57;
    }

    .lead-username {
      color: rgba(0, 0, 0, 0.45);
      font-size: 12px;
      font-weight: 400px;
      line-height: 20px;

      .formatteddate {
        color: #bfbfbf;
      }

      .note-container {
        max-width: 200px !important;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }

      .note {
        color: #525a5c;
      }
    }

    .notes-textarea-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 11px;

      .notes-lead-name {
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        .duration {
          color: #bfbfbf;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .notes-textarea {
        display: flex;
        padding: 5px 12px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 2px;
        border: 1px solid #d9d9d9;
        background: $white;
        min-height: 76px;
        .textarea {
          padding: 0px;
          border: none;
          box-shadow: none;
          display: block;
        }
      }
    }

    .add-notes-btn {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      .add-btn {
        display: flex;
        padding: 4px 15px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 2px;
        color: rgba(0, 0, 0, 0.25);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        border-radius: 2px;
        border: 1px solid var(--Neutral-5, #d9d9d9);
        background: var(--Neutral-3, #f5f5f5);
      }

      .add-btn-enabled {
        background-color: #002244;
        color: $white;
      }
    }
  }
}

.lead-activity-wrapper {
  display: flex;
  padding: 10px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 8px;
  background: $white;

  .lead-activity-title-container {
    // display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .lead-activity-title {
      color: $active-header-text;
      font-size: 15.715px;
      font-style: normal;
      font-weight: 600;
      line-height: 28.286px;
    }
  }
}

.leads-from-same-company-wrapper {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  background-color: $white;
  max-height: 400px;
  overflow-y: auto;
}

.leads-from-same-cmpny {
  color: #263238;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.44px;
}

.sticky-container {
  top: 0px;
  position: sticky;
}

.profile-container {
  max-width: 22%;
}
.activity-container {
  width: 100% !important;
  overflow: auto !important;
  height: 120vh !important;
}
